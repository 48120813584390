import React from 'react'
import { Link } from 'react-router-dom';
import styled from 'styled-components'

const LogoWrapper = styled.div`
  position: relative;
  z-index: 99;
  display: flex;
  align-items: center;
`;

const LogoSVG: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="140"
      height="70"
      version="1.1"
      viewBox="0 0 529.167 264.583"
    >
      <defs>
        <clipPath clipPathUnits="userSpaceOnUse">
          <path
            fill="#0ff"
            stroke="none"
            strokeDasharray="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="4"
            strokeWidth="43.162"
            d="M-750 0H-220.83299999999997V529.167H-750z"
            opacity="0.5"
          ></path>
        </clipPath>
      </defs>
      <g>
        <g fillOpacity="1">
          <path
            fill="#202020"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="11.472"
            d="M31.75 33.867L0 59.267v205.316h74.084v-31.75H31.75z"
          ></path>
          <path
            fill="#c28c2e"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="11.472"
            d="M74.084 0l-31.75 25.4v196.85h31.75z"
          ></path>
          <path
            fill="#c28c2e"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="11.472"
            d="M84.666 54.212v210.371h31.75V79.613z"
          ></path>
          <path
            fill="#202020"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="11.472"
            d="M84.666 0v40.66L127 74.525v190.057h31.75V59.267z"
          ></path>
          <path
            d="M198.438 158.206h40.232l1.837-16.965h-2.685c-1.32 9.722-4.193 13.725-9.328 13.725h-15.688v-54.28c0-3.526 1.979-4.098 6.501-4.098h2.921v-2.145h-23.79v2.145c4.522 0 6.5.572 6.5 4.098v51.278c0 3.526-1.978 4.098-6.5 4.098z"
            style={{ lineHeight: "1.25" }}
            fill="#202020"
            stroke="none"
            strokeWidth="7.897"
            fontFamily="Luiss Serif"
            fontSize="94.764"
            fontStretch="normal"
            fontStyle="normal"
            fontVariant="normal"
            fontWeight="normal"
            letterSpacing="0"
            wordSpacing="0"
          ></path>
          <path
            d="M277.6 137.333l3.722 12.963c.188.62.47 1.858.47 2.382 0 2.097-1.412 3.289-3.485 3.336l-2.685.048v2.144h22.942v-2.144l-1.366-.048c-2.92-.095-5.983-1.239-7.538-6.529l-16.206-55.042h-6.03l-16.535 55.471c-1.13 3.765-4.052 6.148-6.36 6.148h-1.885v2.144h16.253v-2.144h-2.025c-2.78 0-4.005-1.382-4.005-3.288 0-.525.236-1.525.424-2.097l4.005-13.344zm-9.893-34.74l9.186 32.358h-18.938z"
            style={{ lineHeight: "1.25" }}
            fill="#202020"
            stroke="none"
            strokeWidth="7.897"
            fontFamily="Luiss Serif"
            fontSize="94.764"
            fontStretch="normal"
            fontStyle="normal"
            fontVariant="normal"
            fontWeight="normal"
            letterSpacing="0"
            wordSpacing="0"
          ></path>
          <path
            d="M348.819 143.386L318.62 94.443h-14.745v2.145c4.428 0 6.313.62 6.313 4.146v51.23c0 3.717-2.073 4.098-7.02 4.098v2.144h16.677v-2.144c-4.946 0-7.114-.381-7.114-4.098v-50.849l35.945 57.95h2.686v-58.379c0-3.717 2.026-4.098 6.972-4.098v-2.145h-16.63v2.145c4.947 0 7.114.381 7.114 4.098z"
            style={{ lineHeight: "1.25" }}
            fill="#202020"
            stroke="none"
            strokeWidth="7.897"
            fontFamily="Luiss Serif"
            fontSize="94.764"
            fontStretch="normal"
            fontStyle="normal"
            fontVariant="normal"
            fontWeight="normal"
            letterSpacing="0"
            wordSpacing="0"
          ></path>
          <path
            d="M409.927 98.16c-4.806-2.906-9.988-4.908-16.395-4.908-16.865 0-26.664 10.77-26.664 33.073 0 21.397 10.505 33.073 26.334 33.073 6.784 0 14.18-3.24 20.446-9.34v-22.256h-14.18v2.526h2.78c3.156 0 3.533.953 3.533 4.003v12.581c0 3.479-3.44 9.626-12.107 9.626-9.187 0-18.138-3.717-18.138-30.213 0-24.543 7.49-30.309 17.76-30.309 9.423 0 14.087 5.242 14.087 16.775h2.544z"
            style={{ lineHeight: "1.25" }}
            fill="#202020"
            stroke="none"
            strokeWidth="7.897"
            fontFamily="Luiss Serif"
            fontSize="94.764"
            fontStretch="normal"
            fontStyle="normal"
            fontVariant="normal"
            fontWeight="normal"
            letterSpacing="0"
            wordSpacing="0"
          ></path>
          <path
            d="M466.188 94.443h-40.09v2.145c4.522 0 6.5.572 6.5 4.098v51.278c0 3.526-1.978 4.098-6.5 4.098v2.144h41.173l1.838-15.821h-2.686c-1.319 9.722-3.062 13.2-8.197 13.2h-17.76v-29.88h7.207c4.947 0 5.7 1.716 5.7 8.864h2.592v-20.11h-2.591c0 7.1-.754 8.864-5.7 8.864h-7.208V96.969h17.76c4.334 0 5.983 2.192 6.831 8.864h2.497z"
            style={{ lineHeight: "1.25" }}
            fill="#202020"
            stroke="none"
            strokeWidth="7.897"
            fontFamily="Luiss Serif"
            fontSize="94.764"
            fontStretch="normal"
            fontStyle="normal"
            fontVariant="normal"
            fontWeight="normal"
            letterSpacing="0"
            wordSpacing="0"
          ></path>
          <path
            d="M490.866 97.064h6.172c9.563 0 13.756 3.098 13.756 13.44 0 10.245-5.465 13.82-13.427 13.82h-6.5zm-14.368-2.62v2.144c4.522 0 6.5.572 6.5 4.098v51.278c0 3.526-1.978 4.098-6.5 4.098v2.144h22.141v-2.144h-1.272c-4.522 0-6.5-.572-6.5-4.098v-24.829h5.888l19.174 31.071h13.238v-2.144h-4.759l-18.796-29.785c8.338-2.192 13.897-6.576 13.897-15.678 0-13.392-11.542-16.156-22.142-16.156z"
            style={{ lineHeight: "1.25" }}
            fill="#202020"
            stroke="none"
            strokeWidth="7.897"
            fontFamily="Luiss Serif"
            fontSize="94.764"
            fontStretch="normal"
            fontStyle="normal"
            fontVariant="normal"
            fontWeight="normal"
            letterSpacing="0"
            wordSpacing="0"
          ></path>
          <path
            d="M211.984 224.77v-1.395c-2.936 0-4.22-.371-4.22-2.664v-33.33c0-2.292 1.284-2.664 4.22-2.664v-1.394h-13.546v1.394c2.935 0 4.22.372 4.22 2.664v33.33c0 2.293-1.285 2.664-4.22 2.664v1.394z"
            style={{ lineHeight: "1.25" }}
            fill="#c28c2e"
            stroke="none"
            strokeWidth="5.129"
            fontFamily="Luiss Serif"
            fontSize="61.554"
            fontStretch="normal"
            fontStyle="normal"
            fontVariant="normal"
            fontWeight="normal"
            letterSpacing="0"
            wordSpacing="0"
          ></path>
          <path
            d="M229.01 183.323h-9.908v1.394c2.936 0 4.22.372 4.22 2.664v33.33c0 2.293-1.284 2.664-4.22 2.664v1.394h10.06v-1.394c-2.935 0-4.22-.371-4.22-2.664v-33.113l13.089 35.901h.948l12.354-35.653v32.865c0 2.293-1.285 2.664-4.22 2.664v1.394h13.546v-1.394c-2.935 0-4.22-.371-4.22-2.664v-33.33c0-2.292 1.285-2.664 4.22-2.664v-1.394h-9.51l-10.978 31.255z"
            style={{ lineHeight: "1.25" }}
            fill="#c28c2e"
            stroke="none"
            strokeWidth="5.129"
            fontFamily="Luiss Serif"
            fontSize="61.554"
            fontStretch="normal"
            fontStyle="normal"
            fontVariant="normal"
            fontWeight="normal"
            letterSpacing="0"
            wordSpacing="0"
          ></path>
          <path
            d="M277.626 183.323h-9.908v1.394c2.936 0 4.22.372 4.22 2.664v33.33c0 2.293-1.284 2.664-4.22 2.664v1.394h10.06v-1.394c-2.935 0-4.22-.371-4.22-2.664v-33.113l13.089 35.901h.948l12.354-35.653v32.865c0 2.293-1.285 2.664-4.22 2.664v1.394h13.546v-1.394c-2.935 0-4.22-.371-4.22-2.664v-33.33c0-2.292 1.285-2.664 4.22-2.664v-1.394h-9.51l-10.978 31.255z"
            style={{ lineHeight: "1.25" }}
            fill="#c28c2e"
            stroke="none"
            strokeWidth="5.129"
            fontFamily="Luiss Serif"
            fontSize="61.554"
            fontStretch="normal"
            fontStyle="normal"
            fontVariant="normal"
            fontWeight="normal"
            letterSpacing="0"
            wordSpacing="0"
          ></path>
          <path
            d="M347.301 204.046c0-13.939-6.116-21.497-16.268-21.497-10.152 0-16.268 7.558-16.268 21.497 0 13.94 6.116 21.498 16.268 21.498 10.152 0 16.268-7.558 16.268-21.498zm-5.687 0c0 14.373-3.242 19.64-10.58 19.64-7.34 0-10.581-5.267-10.581-19.64 0-14.373 3.241-19.67 10.58-19.67 7.34 0 10.58 5.297 10.58 19.67z"
            style={{ lineHeight: "1.25" }}
            fill="#c28c2e"
            stroke="none"
            strokeWidth="5.129"
            fontFamily="Luiss Serif"
            fontSize="61.554"
            fontStretch="normal"
            fontStyle="normal"
            fontVariant="normal"
            fontWeight="normal"
            letterSpacing="0"
            wordSpacing="0"
          ></path>
          <path
            d="M362.212 223.066v-19.453h3.64c6.42 0 10.64 2.013 10.64 9.664s-4.403 9.789-10.518 9.789zm0-20.94v-17.13h3.853c5.077 0 8.654 1.673 8.654 7.899 0 6.567-2.966 9.23-9.48 9.23zm-9.326-18.803v1.394c2.935 0 4.22.372 4.22 2.664v33.33c0 2.293-1.285 2.664-4.22 2.664v1.394h14.555c8.134 0 14.923-2.013 14.923-11.213 0-6.66-4.159-10.687-10.58-10.78v-.247c4.953-.899 8.684-4.09 8.684-9.727 0-8.642-8.287-9.479-15.534-9.479z"
            style={{ lineHeight: "1.25" }}
            fill="#c28c2e"
            stroke="none"
            strokeWidth="5.129"
            fontFamily="Luiss Serif"
            fontSize="61.554"
            fontStretch="normal"
            fontStyle="normal"
            fontVariant="normal"
            fontWeight="normal"
            letterSpacing="0"
            wordSpacing="0"
          ></path>
          <path
            d="M402.148 224.77v-1.395c-2.936 0-4.22-.371-4.22-2.664v-33.33c0-2.292 1.284-2.664 4.22-2.664v-1.394H388.6v1.394c2.936 0 4.22.372 4.22 2.664v33.33c0 2.293-1.284 2.664-4.22 2.664v1.394z"
            style={{ lineHeight: "1.25" }}
            fill="#c28c2e"
            stroke="none"
            strokeWidth="5.129"
            fontFamily="Luiss Serif"
            fontSize="61.554"
            fontStretch="normal"
            fontStyle="normal"
            fontVariant="normal"
            fontWeight="normal"
            letterSpacing="0"
            wordSpacing="0"
          ></path>
          <path
            d="M408.654 224.77h26.115l1.193-11.028h-1.743c-.857 6.319-2.722 8.921-6.055 8.921H417.98v-35.282c0-2.292 1.284-2.664 4.22-2.664h1.896v-1.394h-15.443v1.394c2.936 0 4.22.372 4.22 2.664v33.33c0 2.293-1.284 2.664-4.22 2.664z"
            style={{ lineHeight: "1.25" }}
            fill="#c28c2e"
            stroke="none"
            strokeWidth="5.129"
            fontFamily="Luiss Serif"
            fontSize="61.554"
            fontStretch="normal"
            fontStyle="normal"
            fontVariant="normal"
            fontWeight="normal"
            letterSpacing="0"
            wordSpacing="0"
          ></path>
          <path
            d="M452.914 224.77v-1.395c-2.936 0-4.22-.371-4.22-2.664v-33.33c0-2.292 1.284-2.664 4.22-2.664v-1.394h-13.547v1.394c2.936 0 4.22.372 4.22 2.664v33.33c0 2.293-1.284 2.664-4.22 2.664v1.394z"
            style={{ lineHeight: "1.25" }}
            fill="#c28c2e"
            stroke="none"
            strokeWidth="5.129"
            fontFamily="Luiss Serif"
            fontSize="61.554"
            fontStretch="normal"
            fontStyle="normal"
            fontVariant="normal"
            fontWeight="normal"
            letterSpacing="0"
            wordSpacing="0"
          ></path>
          <path
            d="M486.055 183.323h-26.023v1.394c2.936 0 4.22.372 4.22 2.664v33.33c0 2.293-1.284 2.664-4.22 2.664v1.394h26.726l1.193-10.284h-1.743c-.856 6.32-1.988 8.58-5.321 8.58h-11.528v-19.421h4.678c3.211 0 3.7 1.115 3.7 5.761h1.682v-13.072h-1.682c0 4.616-.489 5.762-3.7 5.762h-4.678v-17.13h11.528c2.813 0 3.884 1.425 4.434 5.762h1.62z"
            style={{ lineHeight: "1.25" }}
            fill="#c28c2e"
            stroke="none"
            strokeWidth="5.129"
            fontFamily="Luiss Serif"
            fontSize="61.554"
            fontStretch="normal"
            fontStyle="normal"
            fontVariant="normal"
            fontWeight="normal"
            letterSpacing="0"
            wordSpacing="0"
          ></path>
          <path
            d="M522.99 215.136l-19.602-31.813h-9.57v1.394c2.874 0 4.097.403 4.097 2.695v33.3c0 2.416-1.346 2.663-4.557 2.663v1.394h10.825v-1.394c-3.21 0-4.617-.247-4.617-2.664V187.66l23.332 37.667h1.743V187.38c0-2.416 1.315-2.664 4.526-2.664v-1.394h-10.795v1.394c3.21 0 4.618.248 4.618 2.664z"
            style={{ lineHeight: "1.25" }}
            fill="#c28c2e"
            stroke="none"
            strokeWidth="5.129"
            fontFamily="Luiss Serif"
            fontSize="61.554"
            fontStretch="normal"
            fontStyle="normal"
            fontVariant="normal"
            fontWeight="normal"
            letterSpacing="0"
            wordSpacing="0"
          ></path>
        </g>
      </g>
    </svg>
  );
}

const Logo: React.FC = () => {
  return (
    <LogoWrapper>
      <Link to='/'>
        <LogoSVG />
      </Link>
    </LogoWrapper>
  )
}

export default Logo
