import React from 'react'

interface Props {
    
}

const Immobilien: React.FC<Props> = (props) => {
    return (
        <div>
            Immobilien
        </div>
    )
}

export default Immobilien